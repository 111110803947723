<template>
  <section>
      <div class="content-header">
        <h2>Less stress in 3 minutes </h2>
      </div>
      <div class="content-wrapper">
         <!-- <div class="video-player"> -->
        <p>Meditation has been proven to lower stress levels and improve focus. But some people think the road to being able to meditate is a long and arduous journey. </p>
        <p>These short, guided meditations are perfect for beginners and long-time meditation practitioners alike. Coming in at around 3-5 minutes each, these video clips provide a quick dose of calm to help students reduce stress and centre themselves.</p>

        <ul class="">
          <li><a href="https://www.youtube.com/embed/m4Q06mj_2Uo?rel=0&cc_load_policy=1" target="_blank">Three-minute breathing space </a></li>
          <li><a href="https://www.youtube.com/embed/mkFlu9qbvCU?rel=0&cc_load_policy=1" target="_blank">Ocean meditation </a></li>
          <li><a href="https://www.youtube.com/embed/XtH-QWy4CT4?rel=0&cc_load_policy=1" target="_blank">Lake meditation </a></li>
          <li><a href="https://www.youtube.com/embed/1aC5aDVirsc?rel=0&cc_load_policy=1" target="_blank">Mountain meditation </a></li>
          <li><a href="https://www.youtube.com/embed/_PvZecJl0Mw?rel=0&cc_load_policy=1" target="_blank">Loving kindness meditation </a></li>
        </ul>
        <p>These guided meditations are also available on <a href="https://soundcloud.com/user-743147355-683744593/sets/thriveru-medidation-recordings" target="_blank">Soundcloud</a>. </p>
        <p>Used with permission from Diana Brecher.</p>
      </div>
  </section>
</template>

<script>
// import Accordion from '@/components/Accordion.vue'
// import SidebarMenu from '../components/SidebarMenu.vue'
// import {
//   Hooper,
//   Slide,
//   Navigation as HooperNavigation,
//   Pagination as HooperPagination
// } from 'hooper'
// import 'hooper/dist/hooper.css'

export default {
  name: '03',
  components: {
    // SidebarMenu,
    // Accordion
    // Hooper,
    // Slide,
    // HooperNavigation,
    // HooperPagination
  },
  data () {
    return {
      publicPath: process.env.BASE_URL
    }
  },

  mounted () {
  }
}
</script>

<style>

</style>
